import React, { useState, useMemo } from 'react';
import classNames from 'classnames/bind';
import styles from './MediaPlayList.module.scss';
import Slider from '@mui/material/Slider';
import { NavLink } from 'react-router-dom';
import useAuth from 'hooks/useAuth';

const cx = classNames.bind(styles);

export default function MediaPlayList({
  playId,
  playList,
  onClick,
  handleRemove,
  handleClickLike,
  selectedPlay,
  handleUp,
  handleDown,
  handlePlayOrdering,
  handlePlayShuffle,
  handlePlayLoop,
  playLoop,
  playType,
  handlePlayType,
  playVolume,
  handleVolumnChange,
  currentPlayTime,
  handlePlayTimeChange,
  playMute,
  handlePlayMute,
  playStart,
  handlePlayStart,
  hanldeNextPlay,
  hanldePrevPlay,
  playDuration,
  handlePlayDuration,
  handleMoveClick,
  grade,
  handleAgree,
  handleAllAgree,
  setTempMaxCount,
  tempMaxCount,
  isFetching,
}) {
  // 모바일 아래 탭 메뉴
  const [listMenu, setListMenu] = useState(false);

  // 편집 버튼 클릭 시 나오는 메뉴 창
  const [edit, setEdit] = useState(false);

  // const [current, setCurrent] = useState(0);

  // const slider = [
  //     {
  //         image: '/statics/images/whale.jpg'
  //     },
  //     {
  //         image: '/statics/images/moon_beach.jpg'
  //     },
  //     {
  //         image: '/statics/images/abstract_wave.jpg'
  //     },
  //     {
  //         image: '/statics/images/glacier.jpg'
  //     },
  //     {
  //         image: '/statics/images/fireplace.jpg'
  //     }
  // ]

  // const sliderLength = slider.length

  // if (!Array.isArray(slider) || slider.length <= 0) {
  //     return null;
  // }

  // const nextButton = () => {
  //     setCurrent(current === sliderLength - 1 ? 0 : current + 1);
  // }

  // const prevButton = () => {
  //     setCurrent(current === 0 ? sliderLength - 1 : current - 1);
  // }

  const currentPlayVideo = useMemo(() => {
    if (!playList || playList.length === 0) return;
    const hasPlayList = playList.filter((el) => el.id === playId);
    if (!hasPlayList || hasPlayList.length === 0) return;
    return hasPlayList[0];
  }, [playList, playId]);

  const cTime = useMemo(() => {
    if (!currentPlayVideo || !currentPlayTime) return '00:00';
    const minutes = parseInt(
      (currentPlayVideo.videoLength *
        (currentPlayTime / currentPlayVideo.videoLength)) /
        60,
    );
    const seconds = parseInt(
      Math.floor(
        (currentPlayVideo.videoLength *
          (currentPlayTime / currentPlayVideo.videoLength)) %
          60,
      ),
    );

    const MM = minutes >= 10 ? minutes : `0${minutes}`;
    const SS = seconds >= 10 ? seconds : `0${seconds}`;
    return `${MM}:${SS}`;
  }, [currentPlayVideo, currentPlayTime]);

  const { isTestAccount, isTempBlock } = useAuth();

  return (
    <div className={cx('container')}>
      <div className={cx('sub_container')}>
        <div className={cx('side_container')}>
          <div className={cx('side_inner')}>
            <NavLink to="/play-list/search" className={cx('menu_list_box')}>
              <div className={cx('menu_list_item_box')}>
                <img
                  className={cx('img_on')}
                  src="/statics/images/ic_play_list_on.png"
                  alt="play_list_on_icon"
                />
                <img
                  className={cx('img_off')}
                  src="/statics/images/ic_play_list_off.png"
                  alt="play_list_off_icon"
                />
                <p className={cx('list_text')}>빔몬스터 콘텐츠</p>
              </div>
            </NavLink>
            {!isTempBlock && (
              <NavLink to="/play-list/artist" className={cx('menu_list_box')}>
                <div className={cx('menu_list_item_box')}>
                  <img
                    className={cx('img_on')}
                    src="/statics/images/ic-play-search-on.png"
                    alt="search_om_icon"
                  />
                  <img
                    className={cx('img_off')}
                    src="/statics/images/ic-play-search-off.png"
                    alt="search_off_icon"
                  />
                  <p className={cx('list_text')}>아티스트 콘텐츠</p>
                </div>
              </NavLink>
            )}
            <NavLink
              to="/play-list/media"
              className={({ isActive }) =>
                cx('menu_list_box', { active: isActive })
              }
            >
              <div className={cx('menu_list_item_box')}>
                <img
                  className={cx('img_on')}
                  src="/statics/images/ic_play_playlist_on.png"
                  alt="play_playlist_on_icon"
                />
                <img
                  className={cx('img_off')}
                  src="/statics/images/ic_play_playlist_off.png"
                  alt="play_playlist_off_icon"
                />
                <p className={cx('list_text')}>플레이리스트</p>
              </div>
            </NavLink>
            <div className={cx('select_media_box')}></div>
            {/* <div className={cx('bottom_select_inner')}>
                            <div className={cx('select_keep_box')}>
                                <div className={cx('select_keep_text_box')}>
                                    <div className={cx('select_number_text_box')}>
                                        <p className={cx('select_number_text')}>이용 현황</p>
                                    </div>
                                    <div className={cx('selecet_count_box')}>
                                        <p className={cx('selecet_count_number')}>구독권 구매 필요</p>
                                    </div>
                                </div>
                                <div className={cx('select_keep_button_box')}>
                                    <button className={cx('select_keep_button')}>
                                        플레이리스트 보관
                                    </button>
                                </div>
                            </div>
                        </div> */}
          </div>
        </div>
        <div className={cx('inner')}>
          {/* 데이터 없을 떄 */}
          {/* <div className={cx('no_data_inner')}>
                        <div className={cx('no_data_box')}>
                            <div className={cx('no_data_title_box')}>
                                <p className={cx('no_data_title')}>
                                    등록 된 Play List가 없습니다.
                                </p>
                            </div>
                        </div>
                    </div> */}
          <div className={cx('slider_container')}>
            <div className={cx('slider_inner_box')}>
              <div
                className={cx('slider_bg')}
                style={{
                  backgroundImage:
                    currentPlayVideo && currentPlayVideo.thumbnailUrl
                      ? `url(${currentPlayVideo.thumbnailUrl})`
                      : 'url(/statics/images/whale.jpg)',
                }}
              >
                <div className={cx('slider_sub_bg')}>
                  <div
                    className={cx('slider_inner')}
                    style={{
                      backgroundImage:
                        currentPlayVideo && currentPlayVideo.thumbnailUrl
                          ? `url(${currentPlayVideo.thumbnailUrl})`
                          : 'url(/statics/images/whale.jpg)',
                    }}
                  >
                    <div className={cx('slider_box')}>
                      <div className={cx('slider_title_box')}>
                        <div className={cx('sluder_quality_box')}>
                          <p className={cx('sluder_quality')}>
                            {currentPlayVideo?.resolution || '-'}
                          </p>
                        </div>
                        <div className={cx('text_box')}>
                          <p className={cx('text')}>
                            {currentPlayVideo?.title || '-'}
                          </p>
                        </div>
                      </div>
                      <div
                        className={cx('slide_hart_box')}
                        role="presentation"
                        onClick={(e) =>
                          handleClickLike(e, currentPlayVideo?.id)
                        }
                      >
                        {currentPlayVideo?.liked ? (
                          <img
                            className={cx('slide_hart')}
                            src="/statics/images/ic_playlist_like_on.png"
                            alt="slide_hart"
                          />
                        ) : (
                          <img
                            className={cx('slide_hart')}
                            src="/statics/images/slide_hart.png"
                            alt="slide_hart"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={cx('slider_button_inner')}>
              <div className={cx('slider_button_box')}>
                {/* <div role="presentation" style={{
                                    width: '40px',
                                    height: '40px',
                                }}> */}
                <div
                  role="presentation"
                  className={cx('slider_pop_repeat_button')}
                  onClick={() => handlePlayLoop(currentPlayVideo)}
                >
                  {/* 한곡반복 */}
                  {playLoop ? (
                    <img
                      src="/statics/images/ic_pop_repeat_one_on.png"
                      alt="ic_pop_repeat_one_on"
                    />
                  ) : (
                    <img
                      src="/statics/images/ic_pop_repeat_on.png"
                      alt="ic_pop_repeat_on"
                    />
                  )}

                  {/* 전체반복
                                    <img src="/statics/images/ic_pop_repeat_on.png" alt="ic_pop_repeat_on" />
                                    반복 끄기
                                    <img src="/statics/images/ic_pop_repeat_off.png" alt="ic_pop_repeat_off"/> */}
                </div>
                <div className={cx('slider_play_button_inner')}>
                  <div
                    className={cx('slider_skip_button_box')}
                    role="presentation"
                    onClick={() => hanldePrevPlay(currentPlayVideo)}
                  >
                    <img
                      src="/statics/images/ic_pop_prev.png"
                      alt="ic_pop_prev"
                    />
                  </div>
                  <div
                    className={cx('slider_play_button_box')}
                    role="presentation"
                    onClick={() => handlePlayStart(currentPlayVideo)}
                  >
                    {playStart ? (
                      <img
                        src="/statics/images/play_stop.png"
                        alt="play_stop"
                      />
                    ) : (
                      <img
                        src="/statics/images/play_start.png"
                        alt="play_start"
                      />
                    )}
                  </div>
                  <div
                    className={cx('slider_skip_button_box')}
                    role="presentation"
                    onClick={() => hanldeNextPlay(currentPlayVideo)}
                  >
                    <img
                      src="/statics/images/ic_pop_next.png"
                      alt="ic_pop_next"
                    />
                  </div>
                </div>
                {/* <div style={{ width: '40px', height: '40px' }}> */}
                <div
                  role="presentation"
                  onClick={() => handlePlayType(currentPlayVideo)}
                  className={cx('slider_shuffle_button')}
                >
                  {playType === 'Shuffle' ? (
                    <img
                      src="/statics/images/ic_pop_shuffle_on.png"
                      alt="ic_pop_shuffle_on"
                    />
                  ) : (
                    <img
                      src="/statics/images/ic_pop_shuffle_off.png"
                      alt="ic_pop_shuffle_off"
                    />
                  )}
                </div>
              </div>
              <div className={cx('slider_play_time_inner')}>
                <div className={cx('slider_play_time_progress')}>
                  <Slider
                    aria-label="play"
                    value={
                      (currentPlayTime / currentPlayVideo?.videoLength) * 100 ||
                      0
                    }
                    onChange={(e) =>
                      handlePlayTimeChange(e, currentPlayVideo?.videoLength)
                    }
                  />
                </div>
                <div className={cx('slider_play_time_box')}>
                  <p className={cx('slider_play_time')}>{cTime}</p>
                  <p className={cx('slider_play_time')}>
                    {currentPlayVideo &&
                      currentPlayVideo.videoLength &&
                      `${
                        parseInt(currentPlayVideo.videoLength / 60) >= 10
                          ? parseInt(currentPlayVideo.videoLength / 60)
                          : `0${parseInt(currentPlayVideo.videoLength / 60)}`
                      }:${
                        currentPlayVideo.videoLength % 60 >= 10
                          ? currentPlayVideo.videoLength % 60
                          : `0${currentPlayVideo.videoLength % 60}`
                      }`}
                  </p>
                </div>
              </div>
            </div>
            {/* <div className={cx('sound_inner')}>
              <div
                className={cx('sound_box')}
                role="presentation"
                onClick={() => handlePlayMute(currentPlayVideo)}
              >
                {playMute ? (
                  <img
                    src="/statics/images/ic_pop_sound_off.png"
                    alt="ic_pop_sound_off"
                  />
                ) : (
                  <img
                    src="/statics/images/ic_pop_sound_on.png"
                    alt="ic_pop_sound_on"
                  />
                )}
              </div>
              <div className={cx('sound_progressbar')}>
                <Slider
                  aria-label="Volume"
                  value={playVolume}
                  onChange={(e) => handleVolumnChange(e, currentPlayVideo)}
                />
              </div>
            </div> */}
          </div>
          <div className={cx('playlist_inner')}>
            <div className={cx('playlist_button_inner')}>
              <div className={cx('playlist_button_box')}>
                {/* {
                                    edit &&
                                    <div className={cx('mobile_edit_inner')}>
                                        <div className={cx('mobile_select_edit_box')}>
                                            <p className={cx('mobile_select_edit')}>
                                                선택된 <b className={cx('mobile_select_edit_b')}>2개 미디어</b>
                                            </p>
                                        </div>
                                        <div className={cx('mobile_select_edit_option_box')}>
                                            <img className={cx('mobile_select_edit_option_del')} src="/statics/images/ic_play_del_w.png" alt="ic_play_del_w" />
                                            <img className={cx('mobile_select_edit_option_hart')} src="/statics/images/edit_hart.png" alt="edit_hart" />
                                        </div>
                                    </div>
                                } */}
                <div className={cx('playlist_icon_inner')}>
                  <div
                    role="presentation"
                    onClick={() => handlePlayOrdering(currentPlayVideo)}
                    className={cx('playlist_icon_box')}
                  >
                    <img
                      className={cx('playlist_icon')}
                      src="/statics/images/ic_play_turn.png"
                      alt="ic_play_turn"
                    />
                    <div className={cx('playlist_text_box')}>
                      <p className={cx('playlist_text')}>영상 플레이</p>
                    </div>
                  </div>
                  {/* <div
                    role="presentation"
                    onClick={() =>
                      handlePlayOrdering(currentPlayVideo, 'direct')
                    }
                    className={cx('playlist_icon_box')}
                  >
                    <img
                      className={cx('playlist_icon')}
                      src="/statics/images/ic_play_turn.png"
                      alt="ic_play_turn"
                    />
                    <div className={cx('playlist_text_box')}>
                      <p className={cx('playlist_text')}>영상 플레이2</p>
                    </div>
                  </div> */}
                  {/* <div role="presentation" onClick={handlePlayShuffle} className={cx('playlist_icon_box')}>
                                        <img className={cx('playlist_icon')} src="/statics/images/ic_play_shuffle.png" alt="ic_play_shuffle" />
                                        <div className={cx('playlist_text_box')}>
                                            <p className={cx('playlist_text')}>랜덤재생</p>
                                        </div>
                                    </div> */}
                </div>
                {/* <div onClick={() => setEdit(!edit)} className={cx(!edit ? 'mobile_edit_box' : 'active_mobile_edit_box')}>
                                    {
                                        !edit ?
                                            <p className={cx('mobile_edit')}>편집</p> :
                                            <p className={cx('active_mobile_edit')}>완료</p>
                                    }
                                </div> */}
                <div className={cx('playlist_icon_inner')}>
                  {/* <div className={cx('playlist_icon_box', 'box_select')}>
                                        <select name="playDuration" value={tempMaxCount} onChange={(e) => setTempMaxCount(Number(e.target.value))} className={cx('select_duration')}>
                                            <option value="">플레이 최대 개수(제한 없음)</option>
                                            {
                                                Array.from(Array(20).keys()).map((el) => {
                                                    return (
                                                        <option value={el + 1}>{el + 1}개</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div> */}
                  <div className={cx('playlist_icon_box', 'box_select')}>
                    <select
                      name="playDuration"
                      value={playDuration}
                      onChange={handlePlayDuration}
                      className={cx('select_duration')}
                    >
                      <option value="">플레이 지속시간</option>
                      {/* <option value={"auto"}>영상길이만큼</option>
                                            <option value={"20"}>20초</option>
                                            <option value={"30"}>30초</option>
                                            <option value={"60"}>1분</option>
                                            <option value={"120"}>2분</option>
                                            <option value={"180"}>3분</option>
                                            <option value={"240"}>4분</option> */}
                      <option value={'300'}>5분</option>
                      <option value={'600'}>10분</option>
                      <option value={'1800'}>30분</option>
                      <option value={'3600'}>60분</option>
                    </select>
                  </div>
                  <div
                    role="presentation"
                    onClick={handleUp}
                    className={cx('playlist_icon_box')}
                  >
                    <img
                      className={cx('playlist_icon')}
                      src="/statics/images/ic_play_upmove.png"
                      alt="ic_play_upmove"
                    />
                    <div className={cx('playlist_text_box')}>
                      <p className={cx('playlist_text')}>위로 보내기</p>
                    </div>
                  </div>
                  <div
                    role="presentation"
                    onClick={handleDown}
                    className={cx('playlist_icon_box')}
                  >
                    <img
                      className={cx('playlist_icon')}
                      src="/statics/images/ic_play_downmove.png"
                      alt="ic_play_downmove"
                    />
                    <div className={cx('playlist_text_box')}>
                      <p className={cx('playlist_text')}>아래로 보내기</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={cx('playlist_item_header')}>
              <div className={cx('playlist_item_header_title_box')}>
                <p className={cx('playlist_item_header_title')}>
                  {/* <div className={cx('agree_checkbox')}>
                                        <input className={cx('checkbox', { checked: true })} id={`play_check_all`} type="checkbox" name={`play_check_all`} onClick={(e) => handleAllAgree(e)} />
                                        <label className={cx('checklabel')} htmlFor={`play_check_all`}></label>
                                    </div>  */}
                  영상 플레이 포함여부
                </p>
              </div>
              <div className={cx('playlist_item_header_title_box')}>
                <p className={cx('playlist_item_header_title')}>제목</p>
              </div>
              <div
                className={cx(
                  'playlist_item_header_title_box',
                  'playlist_item_header_division_title_box',
                )}
              >
                <p className={cx('playlist_item_header_title')}>구분</p>
              </div>
              <div className={cx('playlist_item_header_title_box')}>
                <p className={cx('playlist_item_header_title')}>재생시간</p>
              </div>
            </div>
            <div className={cx('playlist_item_content_inner')}>
              {!isFetching && (
                <p className={cx('no-data')}>
                  등록하신 플레이 영상 리스트를 로딩 중 입니다.
                </p>
              )}
              {isFetching && playList?.length === 0 && (
                <p className={cx('no-data')}>
                  등록하신 플레이 영상이 없습니다.
                </p>
              )}
              {isFetching &&
                playList &&
                playList.length > 0 &&
                playList.map((el, index) => {
                  return (
                    <div
                      key={el?.id}
                      role="presentation"
                      onClick={(e) => onClick(e, el)}
                      className={cx('playlist_item_content_box', {
                        active: selectedPlay?.id === el.id,
                      })}
                    >
                      <div className={cx('playlist_agree_box')}>
                        <div
                          className={cx('agree_checkbox')}
                          role="presentation"
                        >
                          <input
                            className={cx('checkbox', {
                              checked: el?.isAcutalPlay,
                            })}
                            id={`play_check_${index}`}
                            type="checkbox"
                            name={`play_check_${index}`}
                          />
                          <label
                            className={cx('checklabel')}
                            htmlFor={`play_check_${index}`}
                            onClick={(e) => handleAgree(e, el)}
                          ></label>
                        </div>
                      </div>
                      <div className={cx('playlist_item_content_title_box')}>
                        <div className={cx('playlist_item_img_box')}>
                          <img
                            className={cx('playlist_item_img')}
                            src={
                              el.thumbnailUrl
                                ? el.thumbnailUrl
                                : '/statics/images/palm_tree.jpg'
                            }
                            alt="palm_tree"
                          />
                        </div>
                        <div className={cx('playlist_item_box')}>
                          <div className={cx('playlist_item_quality_box')}>
                            <p className={cx('playlist_item_quality')}>
                              {el?.resolution || '-'}
                            </p>
                          </div>
                          <div className={cx('playlist_item_title_box')}>
                            <h4 className={cx('playlist_item_title')}>
                              {el?.title || '-'}
                            </h4>
                          </div>
                        </div>
                      </div>
                      {/* 구분 내용 */}
                      <div className={cx('playlist_item_division_box')}>
                        <p className={cx('playlist_item_division')}>
                          {el?.creator ? '작가콘텐츠' : '빔몬스터'}
                        </p>
                      </div>
                      <div className={cx('playlist_item_content_play_box')}>
                        <div className={cx('playlist_item_time_box')}>
                          <p className={cx('playlist_item_time')}>
                            {`${
                              parseInt(el.videoLength / 60) >= 10
                                ? parseInt(el.videoLength / 60)
                                : `0${parseInt(el.videoLength / 60)}`
                            }:${
                              el.videoLength % 60 >= 10
                                ? el.videoLength % 60
                                : `0${el.videoLength % 60}`
                            }`}
                          </p>
                        </div>
                        <div className={cx('playlist_item_icon_inner')}>
                          {/* <div>
                                                        <img onClick={(e) => handleRemove(e, index, el.id)} src="/statics/images/ic_play_del.png" alt="ic_play_del" />
                                                    </div> */}
                          <div
                            className={cx('playlist_item_icon_box')}
                            role="presentation"
                            onClick={(e) => handleClickLike(e, el?.id)}
                          >
                            {el?.liked ? (
                              <img
                                className={cx('playlist_item_icon_on_img')}
                                src="/statics/images/ic_playlist_like_on.png"
                                alt="ic_playlist_like_on"
                              />
                            ) : (
                              <img
                                className={cx('playlist_item_icon_off_img')}
                                src="/statics/images/ic_playlist_like_off.png"
                                alt="ic_playlist_like_off"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        {/* 모바일 footer 이동 및 검색 */}
        {!edit && (
          <div className={cx('mobile_footer_inner')}>
            <div className={cx('mobile_footer_box')}>
              {/* <div className={cx(!listMenu ? 'mobile_hide_select_box' : 'active_mobile_hide_select_box')}>
                                <img onClick={() => setListMenu(!listMenu)} className={cx('mobile_hide_button')} src="/statics/images/mobile_select_button.png" alt="mobile_select_button" />
                                <div className={cx('mobile_hide_text_box')}>
                                    <p className={cx('mobile_hide_text')}>
                                        선택된 영상
                                    </p>
                                </div>
                                <div className={cx('mobile_hide_menu_item_inner')}>
                                    <div className={cx('mobile_hide_menu_item_box')}>
                                        <div className={cx('mobile_hide_img_box')}>
                                            <img className={cx('mobile_hide_img')} src="/statics/images/mobile_hide_img.png" alt="mobile_hide_img" />
                                        </div>
                                        <div className={cx('mobile_hide_menu_item_text_box')}>
                                            <p className={cx('mobile_hide_menu_item_text')}>
                                                콘텐츠 제목이 들어가는 부분입니다.
                                                콘텐츠 제목이 들어가는 부분입니다.
                                            </p>
                                        </div>
                                    </div>
                                    <div className={cx('mobile_hide_menu_item_box')}>
                                        <div className={cx('mobile_hide_img_box')}>
                                            <img className={cx('mobile_hide_img')} src="/statics/images/mobile_hide_img.png" alt="mobile_hide_img" />
                                        </div>
                                        <div className={cx('mobile_hide_menu_item_text_box')}>
                                            <p className={cx('mobile_hide_menu_item_text')}>
                                                콘텐츠 제목이 들어가는 부분입니다.
                                                콘텐츠 제목이 들어가는 부분입니다.
                                            </p>
                                        </div>
                                    </div>
                                    <div className={cx('mobile_hide_menu_item_box')}>
                                        <div className={cx('mobile_hide_img_box')}>
                                            <img className={cx('mobile_hide_img')} src="/statics/images/mobile_hide_img.png" alt="mobile_hide_img" />
                                        </div>
                                        <div className={cx('mobile_hide_menu_item_text_box')}>
                                            <p className={cx('mobile_hide_menu_item_text')}>
                                                콘텐츠 제목이 들어가는 부분입니다.
                                                콘텐츠 제목이 들어가는 부분입니다.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className={cx('mobile_hide_menu_item_button_box')}>
                                    <button className={cx('mobile_hide_menu_item_button')}>플레이리스트 보관</button>
                                </div>
                            </div> */}
              <div className={cx('mobile_footer_header_box')}>
                {/* <div className={cx('mobile_footer_select_box')}>
                                    <p className={cx('mobile_footer_select')}>
                                        <b className={cx('mobile_footer_select_b')}>3</b> / 10
                                    </p>
                                </div> */}
                <div className={cx('mobile_footer_header_img_box')}>
                  <img
                    className={cx('mobile_footer_header_img')}
                    src="/statics/images/ic_m_solution.png"
                    alt="ic_m_solution"
                  />
                </div>
                <div className={cx('mobile_footer_header_title_box')}>
                  <p className={cx('mobile_footer_header_title')}>
                    {grade} 솔루션 이용중
                  </p>
                </div>
              </div>
              <div className={cx('mobile_footer_menu_inner')}>
                <NavLink to="/play-list/search" className={cx('menu_list_box')}>
                  <div className={cx('menu_list_item_box')}>
                    <img
                      className={cx('img_on')}
                      src="/statics/images/ic_play_list_on.png"
                      alt="play_list_on_icon"
                    />
                    <img
                      className={cx('img_off')}
                      src="/statics/images/ic_play_list_off.png"
                      alt="play_list_off_icon"
                    />
                    <p className={cx('list_text')}>빔몬스터 콘텐츠</p>
                  </div>
                </NavLink>
                {!isTempBlock && (
                  <NavLink
                    to="/play-list/artist"
                    className={cx('menu_list_box')}
                  >
                    <div className={cx('menu_list_item_box')}>
                      <img
                        className={cx('img_on')}
                        src="/statics/images/ic-play-search-on.png"
                        alt="search_om_icon"
                      />
                      <img
                        className={cx('img_off')}
                        src="/statics/images/ic-play-search-off.png"
                        alt="search_off_icon"
                      />
                      <p className={cx('list_text')}>아티스트 콘텐츠</p>
                    </div>
                  </NavLink>
                )}
                <NavLink
                  to="/play-list/media"
                  className={({ isActive }) =>
                    cx('menu_list_box', { active: isActive })
                  }
                >
                  <div className={cx('menu_list_item_box')}>
                    <img
                      className={cx('img_on')}
                      src="/statics/images/ic_play_playlist_on.png"
                      alt="play_playlist_on_icon"
                    />
                    <img
                      className={cx('img_off')}
                      src="/statics/images/ic_play_playlist_off.png"
                      alt="play_playlist_off_icon"
                    />
                    <p className={cx('list_text')}>플레이리스트</p>
                  </div>
                </NavLink>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
