import React from 'react';
import useAuth from 'hooks/useAuth';
import useEstimate from 'hooks/useEstimate';
import useCompany from 'hooks/useCompany';
import useContract from 'hooks/useContract';
import classNames from 'classnames/bind';
import styles from './Footer.module.scss';
import { useNavigate } from 'react-router-dom';

const cx = classNames.bind(styles);

export default function Footer() {
  const { isLogIn, isTestAccount, isTempBlock } = useAuth();

  const { company } = useCompany();

  const { estimate } = useEstimate();
  const { contract } = useContract();

  const navigator = useNavigate();

  // 서비스 페이지
  const serviceNavigator = () => {
    navigator('/intro/service');
    window.scrollTo(0, 0);
  };

  // 솔루션 페이지
  const solutionNavigator = () => {
    window.location.href = 'https://beammonsterstore.wixsite.com/store';
    // if (!isLogIn) {
    //   alert('솔루션 신청을 위해 로그인이 필요합니다 : )');
    //   navigator('/login');
    //   return;
    // }

    // switch (estimate?.status) {
    //   case 'READY':
    //     navigator('/solution/choice-product');
    //     break;
    //   case 'READY_LED_SIZE_DONE':
    //     navigator('/solution/request-estimate/led');
    //     break;
    //   case 'READY_BEAM_SIZE_DONE':
    //     navigator('/solution/request-estimate/beam');
    //     break;
    //   case 'READY_ADDRESS_DONE':
    //     navigator('/solution/request-estimate/place');
    //     break;
    //   case 'READY_SOLUTION_TYPE_DONE':
    //     navigator('/solution/request-estimate/solution');
    //     break;
    //   case 'PENDING_CHECK_ADMIN':
    //     navigator('/solution/install/request');
    //     break;
    //   case 'ADMIN_REJECTED':
    //     navigator('/solution/install/fail');
    //     break;
    //   case 'ADMIN_CONFIRM':
    //     navigator('/solution/install/success');
    //     break;
    //   case 'SUBMIT':
    //     navigator('/solution/publish-estimate/wait');
    //     break;
    //   case 'CONFIRM':
    //     if (contract?.paymentType === 'ONCE') {
    //       if (contract?.status === 'READY') {
    //         navigator('/solution/contract-waiting');
    //       } else if (contract?.status === 'UPLOADED') {
    //         if (contract?.clientSignPdfUrl) {
    //           navigator('/solution/deposit-wait');
    //         } else {
    //           navigator('/solution/contract-complete');
    //         }
    //       } else if (contract?.status === 'MONEY_CHECKED') {
    //         navigator('/solution/deposit-complete');
    //       } else if (contract?.status === 'SCHEDULED') {
    //         navigator('/solution/schedule-complete');
    //       } else if (contract?.status === 'FINISH') {
    //         navigator('/solution/success');
    //       } else {
    //         navigator('/solution/publish-estimate/payment');
    //       }
    //     } else if (contract?.paymentType === 'RENTAL') {
    //       if (contract?.status === 'READY') {
    //         navigator('/solution/rental-info');
    //       } else if (contract?.status === 'RENTAL_READY') {
    //         navigator('/solution/rental-wait');
    //       } else if (contract?.status === 'RENTAL_CHECKED') {
    //         navigator('/solution/contract-waiting');
    //       } else if (contract?.status === 'UPLOADED') {
    //         if (contract?.clientSignPdfUrl) {
    //           navigator('/solution/deposit-wait');
    //         } else {
    //           navigator('/solution/contract-complete');
    //         }
    //       } else if (contract?.status === 'MONEY_CHECKED') {
    //         navigator('/solution/deposit-complete');
    //       } else if (contract?.status === 'SCHEDULED') {
    //         navigator('/solution/schedule-complete');
    //       } else if (contract?.status === 'FINISH') {
    //         navigator('/solution/success');
    //       } else {
    //         navigator('/solution/publish-estimate/payment');
    //       }
    //     } else {
    //       navigator('/solution/publish-estimate/payment');
    //     }
    //     break;
    //   default:
    //     navigator('/solution/choice-product');
    //     break;
    // }

    // window.scrollTo(0, 0);
  };

  // 고객센터 페이지
  const privacyNavigator = () => {
    navigator('/privacy');
    window.scrollTo(0, 0);
  };

  // 고객센터 페이지
  const termsNavigator = () => {
    navigator('/terms');
    window.scrollTo(0, 0);
  };

  // 고객센터 페이지
  const mypageNavigator = () => {
    navigator('/mypage/faq');
    window.scrollTo(0, 0);
  };

  // 환불정책 페이지
  const refundNavigator = () => {
    navigator('/refund');
    window.scrollTo(0, 0);
  };

  return (
    <div>
      <div className={cx('container')}>
        <div className={cx('sub_container')}>
          <div className={cx('logo_box')}>
            <img src="/statics/images/footer_logo.png" alt="footer_logo" />
          </div>
          <div className={cx('inner')}>
            <div className={cx('inner_box')}>
              <div className={cx('info_inner')}>
                <div>
                  <div className={cx('info_menu_box')}>
                    <div className={cx('info_menu_item')}>
                      <button
                        className={cx('info_menu_item_text')}
                        onClick={privacyNavigator}
                      >
                        개인정보처리방침
                      </button>
                    </div>
                    {!isTempBlock && (
                      <div className={cx('info_menu_item')}>
                        <button
                          className={cx('info_menu_item_text')}
                          onClick={refundNavigator}
                        >
                          환불정책
                        </button>
                      </div>
                    )}
                    <div className={cx('info_menu_item')}>
                      <button
                        className={cx('info_menu_item_text')}
                        onClick={termsNavigator}
                      >
                        이용약관
                      </button>
                    </div>
                    <div className={cx('info_menu_item')}>
                      <button
                        onClick={mypageNavigator}
                        className={cx('info_menu_item_text')}
                      >
                        고객센터
                      </button>
                    </div>
                    <div className={cx('info_menu_item')}>
                      <button
                        onClick={serviceNavigator}
                        className={cx('info_menu_item_text')}
                      >
                        서비스소개
                      </button>
                    </div>
                    <div className={cx('info_menu_item')}>
                      <button
                        onClick={solutionNavigator}
                        className={cx('info_menu_item_text')}
                      >
                        솔루션구매
                      </button>
                    </div>
                  </div>
                  <div className={cx('info_box')}>
                    <p className={cx('info_text')}>
                      대표 {company?.ceoName || '-'} | 사업자 등록번호{' '}
                      {company?.businessNumber || '-'}{' '}
                      <b className={cx('mobile_b')}>|</b>{' '}
                      <br className={cx('mobile_br')} /> 통신판매업 신고번호:{' '}
                      {company?.onlineSaleRegistrationNumber || '-'}
                    </p>
                    <p className={cx('info_text')}>{company?.address || '-'}</p>
                    <p className={cx('info_text')}>
                      Email : {company?.email || '-'}
                    </p>
                    <p className={cx('info_text')}>
                      대표번호 : {company?.phone || '-'}
                    </p>
                  </div>
                </div>
              </div>
              <div className={cx('icon_inner')}>
                <div
                  className={cx('icon_box')}
                  role="presentation"
                  onClick={() =>
                    company?.youtubeUrl &&
                    window.open(
                      company?.youtubeUrl.indexOf('http') > -1
                        ? company?.youtubeUrl
                        : `http://${company?.youtubeUrl}`,
                    )
                  }
                >
                  <img
                    src="/statics/images/footer_sns_youtube.png"
                    alt="utube"
                  />
                </div>
                <div
                  className={cx('icon_box')}
                  role="presentation"
                  onClick={() =>
                    company?.facebookUrl &&
                    window.open(
                      company?.facebookUrl.indexOf('http') > -1
                        ? company?.facebookUrl
                        : `http://${company?.facebookUrl}`,
                    )
                  }
                >
                  <img src="/statics/images/footer_sns_fb.png" alt="facebook" />
                </div>
                <div
                  className={cx('icon_box')}
                  role="presentation"
                  onClick={() =>
                    company?.instagramUrl &&
                    window.open(
                      company?.instagramUrl.indexOf('http') > -1
                        ? company?.instagramUrl
                        : `http://${company?.instagramUrl}`,
                    )
                  }
                >
                  <img
                    src="/statics/images/footer_sns_insta.png"
                    alt="instar"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
