import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { createBrowserHistory } from 'history';
import axios from 'axios';
import { store, persistor } from 'store';
import ReactGA from 'react-ga';
import App from 'App';
import ReactPixel from 'react-facebook-pixel';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { ExtraErrorData } from '@sentry/integrations';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './styles/reset.module.scss';

Sentry.init({
  dsn: 'https://0e8104b51f1889f28f5259ee69d61e01@o4506092415680512.ingest.sentry.io/4506092425969664',
  environment: 'production',
  normalizeDepth: 6,
  integrations: [
    new Sentry.Integrations.Breadcrumbs({ console: true }),
    new ExtraErrorData({ depth: 10 }),
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        'localhost',
        'https://d10twrpl7cppt3.cloudfront.net',
        'https://beammonster.com',
      ],
    }),
    new Sentry.Replay({}),
  ],
  allowUrls: [
    'localhost',
    'https://d10twrpl7cppt3.cloudfront.net',
    'https://beammonster.com',
    'https://player.vimeo.com/',
    'https://download-video.akamaized.net/',
    'https://api.beammonster.com/api/',
  ],
  beforeSend(event) {
    console.log(event);
    return event;
  },
  maxValueLength: 100000,
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const advancedMatching = { em: 'psysig1@nate.com', country: 'ko' };
const options = {
  autoConfig: true,
  debug: false,
};
ReactPixel.init(process.env.REACT_APP_PIXEL_ID, advancedMatching, options);

const customHistory = createBrowserHistory();
const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID;

ReactGA.initialize(TRACKING_ID);

// axios default setting
axios.defaults.baseURL = process.env.REACT_APP_API;
axios.interceptors.request.use(
  (config) => {
    if (!config?.headers?.Authorization) {
      const token = localStorage.getItem('token');

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }

    return config;
  },
  (error) => Promise.reject(error),
);

ReactDOM.render(
  <BrowserRouter history={customHistory}>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </BrowserRouter>,
  document.getElementById('root'),
);
