import React, { useState, useEffect, useRef, useCallback } from 'react';
import Vimeo from '@u-wave/react-vimeo';
import { useNavigate } from 'react-router-dom';
import * as api from 'apis';
import qs from 'query-string';
import usePlayList from 'hooks/usePlayList';
import PlayWrapper from 'components/PlayWrapper';
import { useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import BeamMonsterLoader from 'components/common/BeamMonsterLoader';
import AdvertisementPlay from 'components/views/AdvertisementPlay';
import useAuth from 'hooks/useAuth';
import Player from '@vimeo/player';
import moment from 'moment';
import _ from 'lodash/fp';
import $ from 'jquery';
import axios from 'axios';

var ADVERTISE_TYPE = {
  A: 'a',
  B: 'b',
  C: 'c',
  D: 'd',
  E: 'e',
  F: 'f',
};

var realPlayList;
var realPlayDuration = 0;
var leftDuration = 0;
var isLoop = false;
var isOnlyOne = false;
var isPreparing = false;
var isUserStop = false;
var isVideoLengthPlay = false;
var containerPlayIndex = 0;
var CHECK_STATUS = true;
var currentPlayer;
var nextPlayer;
var finalPlayer;
var adPlayer;
var currentVideoIndex = 0;
var nextVideoIndex = 0;
var totalElapsedTime = 0;
var lastUpdateTime = 0;

export default function PlayContainer() {
  const navigator = useNavigate();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isInit, setIsInit] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const [videoType, setVideoType] = useState('4k');
  const [videoMode, setVideoMode] = useState('low');
  const [playInfo, setPlayInfo] = useState({
    playType: 'Ordering',
    playList: [],
    shufflePlayList: [],
    playId: null,
    playStart: true, // 플레이중
    playLoop: false, // 반복
    playMute: true, // 음소거
    playVolume: 100,
    currentPlayTime: null,
    selectedPlay: null,
    playDuration: '300', // 5분 Default
    realPlayList: [],
  });

  const [needRefetch, setNeedRefetch] = useState(true);
  const [advertiseInterval, setAdvertiseInterval] = useState(10);
  const [currentAdvertise, setCurrentAdvertise] = useState();
  const [today, setToday] = useState();
  const [advertises, setAdvertises] = useState([]);
  const [logs, setLogs] = useState({});

  const timeRef = useRef(null);

  const playThrottle = useRef(null);
  const currentPlayerRef = useRef(null);
  const nextPlayerRef = useRef(null);

  const location = useLocation();
  const { videoQuality, videoMethod, isDirect } = qs.parse(location.search);

  const {
    name,
    phone,
    contract,
    estimate,
    email,
    businessName,
    businessAddress,
    handleResetAuthData,
  } = useAuth();

  const loadVimeoPlayerScript = () => {
    return new Promise((resolve, reject) => {
      if (document.getElementById('vimeo-player-script')) {
        resolve();
        return;
      }

      const script = document.createElement('script');
      script.id = 'vimeo-player-script';
      script.src = '/statics/js/player.js';
      script.onload = () => resolve();
      script.onerror = () =>
        reject(new Error('Failed to load Vimeo Player script'));
      document.body.appendChild(script);
    });
  };

  const unloadVimeoPlayerScript = () => {
    const script = document.getElementById('vimeo-player-script');
    if (script) {
      document.body.removeChild(script);
    }
  };

  useEffect(() => {
    return () => {
      // 스크립트 언로드
      unloadVimeoPlayerScript();
    };
  }, []);

  const makeVideo = async (videoUrl) => {
    try {
      console.log('makeVideo bbb ', videoUrl);
      await loadVimeoPlayerScript();
      console.log('makeVideo aaa ', videoUrl);

      const iframe = document.createElement('iframe');
      iframe.src = `${videoUrl}&autoplay=1&loop=1&autopause=0&muted=1&title=0&byline=0&portrait=0&sidedock=0&controls=0&transparent=0&background=1`; // 여기에 Vimeo 비디오 ID를 넣으세요
      iframe.style.width = '100vw';
      iframe.style.height = '100vh';
      iframe.style.border = 'none';
      iframe.setAttribute('allowfullscreen', '');

      // const player = await new Player(iframe);
      console.log('window.Vimeo ', window.Vimeo.Player);
      const player = new window.Vimeo.Player(iframe);
      console.log('window.Vimeo player ', player);

      $('#video-container').append(iframe);

      return player;
    } catch (err) {
      console.log('makeVideo err ', err);
      console.log('makeVideo err response ', err?.response);
      return;
    }
  };

  const makeAdVideo = async (videoUrl) => {
    try {
      // await loadVimeoPlayerScript();

      const iframe = document.createElement('iframe');
      iframe.src = `${videoUrl}&autopause=0&muted=1&title=0&byline=0&portrait=0&sidedock=0&controls=0&transparent=0&background=1`; // 여기에 Vimeo 비디오 ID를 넣으세요
      iframe.style.width = '100vw';
      iframe.style.height = '100vh';
      iframe.style.border = 'none';
      iframe.setAttribute('allowfullscreen', '');

      // const player = await new Player(iframe);
      console.log('window.Vimeo ', window.Vimeo);
      const player = new window.Vimeo.Player(iframe);

      $('#ad-container').append(iframe);

      return player;
    } catch (err) {
      console.log('makeAdVideo err', err);
      return;
    }
  };

  const handleVideoIssue = async () => {
    console.log('handleVideoIssue!!! realPlayList ', realPlayList);
    console.log('handleVideoIssue!!! currentVideoIndex ', currentVideoIndex);
    console.log('handleVideoIssue!!! currentPlayer ', currentPlayer);
    console.log('handleVideoIssue!!! playInfo ', playInfo);
    if (isUserStop) return;

    if (!realPlayList || realPlayList.length === 0) {
      console.error('realPlayList is empty');
      return;
    }

    if (currentVideoIndex < 0 || currentVideoIndex >= realPlayList.length) {
      console.error('currentVideoIndex is out of bounds ', currentVideoIndex);
      return;
    }

    try {
      setIsLoading(true);

      if (currentPlayer) {
        console.log('currentPlayer off off ', currentPlayer);
        currentPlayer.off('pause');
        currentPlayer.off('error');
        currentPlayer.off('timeupdate');
        currentPlayer.off('bufferend');
        currentPlayer = null;
      }
      console.log('currentPlayer pass ', currentPlayer);
      isPreparing = false;
      $('#video-container').empty();

      await handleVideo(
        realPlayList[currentVideoIndex].id,
        currentVideoIndex,
        playInfo.playStart,
        true,
      );
    } catch (err) {
      console.log('handleOnlineVideo err ', err);
      console.log('handleOnlineVideo err response ', err?.response);

      const scope = new Sentry.Scope();
      scope.setTag('video', 'videoPauseError');

      if (err?.config) {
        const { method, url, params, data, headers } = err.config; // axios의 error객체

        scope.setContext('pause API Request Detail', {
          method,
          url,
          params,
          data,
          headers,
        });
      }

      if (err?.response) {
        const { data, status } = err.response;

        scope.setContext('pause API Response Detail', {
          status,
          data,
        });
      }

      scope.setContext('pauseContext', {
        err: err?.response,
        errorPause: {
          currentVideoIndex,
          videoId: realPlayList[currentVideoIndex]?.id || null,
          timestamp: moment(new Date()).format('YYYY-MM-DD hh:mm:ss'),
        },
        name,
        phone,
        estimate,
        contract,
        email,
        businessName,
        businessAddress,
      });

      Sentry.captureException(err, () => scope);
    }
  };

  var fadeInOut = async (currentPlayer2, nextPlayer2, nextIndex) => {
    if (!currentPlayer2 || !currentPlayer2?.element) {
      console.log('currentPlayer is not defined ', currentPlayer2);
      return;
    }

    try {
      console.log('fadeInOut called from:', new Error().stack);
      console.log('fadeInOutfadeInOut currentPlayer1', currentPlayer);
      console.log('fadeInOutfadeInOut currentPlayer2', currentPlayer2);
      console.log('fadeInOutfadeInOut nextPlayer2', nextPlayer2);
      console.log('fadeInOutfadeInOut realPlayList ', realPlayList);
      console.log('fadeInOutfadeInOut nextIndex ', nextIndex);
      console.log('fadeInOutfadeInOut nextVideoIndex bbb ', nextVideoIndex);

      nextVideoIndex = (nextIndex + 1) % realPlayList.length;
      console.log('fadeInOutfadeInOut nextVideoIndex aaa ', nextVideoIndex);
      var nextRes;
      try {
        nextRes = await api.getContent(realPlayList[nextVideoIndex].id, true);
      } catch {
        console.log('nextRes!!!');
        // lastUpdateTime = 0;
        // totalElapsedTime = 0;
        nextVideoIndex = (nextVideoIndex + 1) % realPlayList.length;
        nextRes = await api.getContent(realPlayList[nextVideoIndex].id, true);
      }

      console.log('fadeInOutfadeInOut nextVideoIndex final ', nextVideoIndex);

      console.log('nextRes ', nextRes);

      await Promise.all([
        new Promise((resolve) => {
          $(currentPlayer2?.element)
            .removeClass('active')
            .animate({ opacity: 0 }, 5000, function () {
              console.log('$(this) ', $(this));
              $(this).remove(); // 현재 요소를 제거합니다.
              resolve();
            });
        }),
        new Promise((resolve) => {
          $(nextPlayer2?.element).css('opacity', 0);
          $(nextPlayer2?.element)
            .addClass('active')
            .animate({ opacity: 1 }, 3000, function () {
              resolve();
            });
        }),
      ]);

      currentPlayer2.off('timeupdate');
      currentPlayer2.off('pause');
      currentPlayer2.off('error');
      currentPlayer2.off('bufferend');
      currentPlayer = nextPlayer2;

      currentPlayer.on('pause', handleVideoIssue);
      currentPlayer.on('error', handleVideoIssue);
      currentPlayer.on('timeupdate', async function (data) {
        if (!currentPlayer || !currentPlayer?.element) {
          console.log('currentPlayer is not defined ', currentPlayer);
          return;
        }

        const currentTime = (await currentPlayer?.getCurrentTime()) || 0;
        const currentTimeStamp = Date.now();
        const elapsedTimeSinceLastUpdate =
          ((currentTimeStamp -
            (lastUpdateTime === 0 ? currentTimeStamp : lastUpdateTime)) /
            1000) %
          60; // 초 단위로 변환
        lastUpdateTime = currentTimeStamp;

        totalElapsedTime += elapsedTimeSinceLastUpdate;

        console.log('currentPlayer?.paused ', await currentPlayer?.getPaused());
        console.log('isPreparing ', isPreparing);
        console.log('timeupdate ', data);
        console.log('leftDuration ', leftDuration);
        console.log('totalElapsedTime ', totalElapsedTime);
        console.log(
          '!isPreparing && totalElapsedTime >= leftDuration ',
          !isPreparing && totalElapsedTime >= leftDuration,
        );
        console.log('check currentPlayer ', currentPlayer);
        console.log('check currentPlayer2 ', currentPlayer2);
        console.log('check nextPlayer ', nextPlayer);
        console.log('check nextPlayer2 ', nextPlayer2);

        window?.opener?.postMessage(
          { currentPlayTime: currentTime },
          process.env.REACT_APP_ORIGIN,
        );

        if (isOnlyOne || isLoop) {
          totalElapsedTime = 0;
          if (!isUserStop) {
            console.log('fadeInOut isLoop || isOnlyOne play !!! ');
            currentPlayer?.setVolume(0);
            await currentPlayer?.play();
          }
        } else if (!isPreparing && totalElapsedTime >= leftDuration) {
          console.log('next!!!!! realPlayList bbb ', realPlayList);
          isPreparing = true;
          console.log('next!!!!! currentVideoIndex aaa ', currentVideoIndex);
          currentVideoIndex = nextVideoIndex;
          const nextIndex = currentVideoIndex;
          const player = await makeVideo(nextRes?.data?.videoUrl);
          nextPlayer = player;
          console.log('here123 ', player);
          console.log('nextPlayer123 ', nextPlayer);
          console.log('nextPlayer2 456 ', nextPlayer2);
          console.log('finalPlayer123 ', finalPlayer);
          nextPlayer.on('bufferend', async function () {
            console.log('bufferend!!!!! ');
            nextPlayer.setVolume(0);
            nextPlayer.play();
            lastUpdateTime = 0;
            totalElapsedTime = 0;
            console.log(
              'fadeInOutfadeInOut nextVideoIndex gogo ',
              nextVideoIndex,
            );
            console.log(
              'fadeInOutfadeInOut currentVideoIndex gogo ',
              currentVideoIndex,
            );
            await fadeInOut(currentPlayer, nextPlayer, currentVideoIndex);
            console.log('fadeInOut finish isPreparing bbb ', isPreparing);
            isPreparing = false;
            console.log('fadeInOut finish isPreparing aaa ', isPreparing);
            console.log('fadeInOutfadeInOut nextIndex gogo ', nextIndex);
            window?.opener?.postMessage(
              {
                currentPlayTime: 0,
                playId: realPlayList[nextIndex].id,
              },
              process.env.REACT_APP_ORIGIN,
            );
          });
        } else {
          if (!isUserStop) {
            console.log('fadeInOut wow play!!!! ');
            currentPlayer?.setVolume(0);
            await currentPlayer?.play();
          }
        }
      });

      const isPaused = await currentPlayer?.getPaused();
      console.log('currentPlayer look status paused ', isPaused);
      if (isPaused) {
        console.log('currentPlayer is paused, resuming...');
        if (!isUserStop) {
          console.log('fadeInOut start play!!!! ');
          currentPlayer?.setVolume(0);
          await currentPlayer?.play();
        }
      }

      nextPlayer.off('bufferend');
      nextPlayer = null;
    } catch (err) {
      console.log('errrrrr!!!! ', err);
      console.log('errrrrr!!!! response ', err?.response);

      const scope = new Sentry.Scope();
      scope.setTag('video', 'fadeInoutError');

      if (err?.config) {
        const { method, url, params, data, headers } = err.config; // axios의 error객체

        scope.setContext('fadeInoutError Detail', {
          method,
          url,
          params,
          data,
          headers,
        });
      }

      if (err?.response) {
        const { data, status } = err.response;

        scope.setContext('fadeInoutError Detail', {
          status,
          data,
        });
      }

      scope.setContext('pauseContext', {
        err: err?.response,
        errorPause: {
          currentVideoIndex,
          videoId: realPlayList[currentVideoIndex]?.id || null,
          timestamp: moment(new Date()).format('YYYY-MM-DD hh:mm:ss'),
        },
        name,
        phone,
        estimate,
        contract,
        email,
        businessName,
        businessAddress,
      });

      Sentry.captureException(err, () => scope);
      // lastUpdateTime = 0;
      // totalElapsedTime = 0;
      // await fadeInOut(currentPlayer, nextPlayer);
      // isPreparing = false;
    }
  };

  const handleVideo = async (videoId, currentIndex, playStart, isIssue) => {
    try {
      console.log('handleVideo currentIndex ', currentIndex);
      console.log('handleVideo isIssue ', isIssue);
      const res = await api.getContent(videoId, true);
      const player = await makeVideo(res?.data?.videoUrl);
      console.log('currentPlayer currentPlayer bbb ', currentPlayer);
      currentPlayer = player;
      console.log('currentPlayer currentPlayer aaa ', currentPlayer);

      currentPlayer.on('bufferend', function () {
        console.log('currentPlayer bufferend ');
        if (playStart) {
          console.log('currentPlayer bufferend playStart');
          currentPlayer.setVolume(0);
          currentPlayer.play();
        }

        console.log('currentPlayer bufferend active');
        $(currentPlayer.element).addClass('active');
        setIsLoading(false);
      });

      if (isIssue) {
        console.log('currentPlayer isIssue playStart ', playStart);
        if (playStart) {
          console.log('currentPlayer isIssue play ');
          currentPlayer?.setVolume(0);
          currentPlayer.play();
        }

        console.log('currentPlayer isIssue active');
        $(currentPlayer.element).addClass('active');
        setIsLoading(false);
      }

      console.log('currentPlayer just go');

      currentPlayer.on('timeupdate', async function (data) {
        const currentTime = (await currentPlayer?.getCurrentTime()) || 0;
        const currentTimeStamp = Date.now();
        const elapsedTimeSinceLastUpdate =
          ((currentTimeStamp -
            (lastUpdateTime === 0 ? currentTimeStamp : lastUpdateTime)) /
            1000) %
          60; // 초 단위로 변환
        lastUpdateTime = currentTimeStamp;

        totalElapsedTime += elapsedTimeSinceLastUpdate;
        console.log('isPreparing ', isPreparing);
        console.log('timeupdate ', data);
        console.log('leftDuration ', leftDuration);
        console.log('totalElapsedTime ', totalElapsedTime);
        console.log(
          '!isPreparing && totalElapsedTime >= leftDuration ',
          !isPreparing && totalElapsedTime >= leftDuration,
        );
        window?.opener?.postMessage(
          { currentPlayTime: currentTime },
          process.env.REACT_APP_ORIGIN,
        );
        if (isOnlyOne || isLoop) {
          totalElapsedTime = 0;
          if (!isUserStop) {
            console.log('handleVideo isLoop || isOnlyOne play !!! ');
            currentPlayer?.setVolume(0);
            await currentPlayer?.play();
          }
        } else if (!isPreparing && totalElapsedTime >= leftDuration) {
          console.log('change!!!! ');
          isPreparing = true;
          currentVideoIndex = (currentIndex + 1) % realPlayList.length;
          const nextVideoIndex = (currentIndex + 1) % realPlayList.length;
          const res = await api.getContent(
            realPlayList[nextVideoIndex].id,
            true,
          );
          const player = await makeVideo(res?.data?.videoUrl);
          nextPlayer = player;
          nextPlayer.on('bufferend', async function () {
            // 비디오를 자동 재생
            nextPlayer.setVolume(0);
            nextPlayer.play();
            lastUpdateTime = 0;
            totalElapsedTime = 0;
            console.log(
              'handleVideo currentVideoIndex gogo ',
              currentVideoIndex,
            );
            console.log('handleVideo nextVideoIndex bbb ', nextVideoIndex);
            await fadeInOut(currentPlayer, nextPlayer, nextVideoIndex);
            console.log('fadeInOut finish isPreparing bbb ', isPreparing);
            isPreparing = false;
            console.log('fadeInOut finish isPreparing bbb ', isPreparing);
            console.log('handleVideo nextVideoIndex aaa ', nextVideoIndex);
            window?.opener?.postMessage(
              {
                currentPlayTime: 0,
                playId: realPlayList[nextVideoIndex].id,
              },
              process.env.REACT_APP_ORIGIN,
            );
          });
        } else {
          if (!isUserStop) {
            console.log('handleVideo play !!! ');
            currentPlayer?.setVolume(0);
            await currentPlayer?.play();
          }
        }
      });
      currentPlayer.on('pause', handleVideoIssue);
      currentPlayer.on('error', handleVideoIssue);
    } catch (err) {}
  };

  const handleUserKeyPress = useCallback(
    (event) => {
      if (event.keyCode === 27) {
        if (isFullScreen) {
          setIsFullScreen(false);
        }
      }
    },
    [isFullScreen, setIsFullScreen],
  );

  const exitHandler = useCallback(() => {
    if (
      !document.webkitIsFullScreen &&
      !document.mozFullScreen &&
      !document.msFullscreenElement
    ) {
      setIsFullScreen(false);
    }
  }, [isFullScreen, setIsFullScreen]);

  const handlePlayCurrentVideo = async (id, index, playStart) => {
    try {
      console.log('handlePlayCurrentVideo id ', id);
      console.log('handlePlayCurrentVideo index ', index);
      setIsLoading(true);
      if (currentPlayer) {
        currentPlayer.off('pause');
        currentPlayer.off('error');
        currentPlayer.off('timeupdate');
        currentPlayer.off('bufferend');
        currentPlayer = null;
      }
      isPreparing = false;
      $('#video-container').empty();
      console.log(
        'handlePlayCurrentVideo currentVideoIndex bbb ',
        currentVideoIndex,
      );
      currentVideoIndex = index;
      console.log(
        'handlePlayCurrentVideo currentVideoIndex aaa ',
        currentVideoIndex,
      );
      await handleVideo(id, index, playStart, false);
    } catch (err) {
      console.error(err);
    }
  };

  const handleParentData = async (data) => {
    setPlayInfo(data);
    console.log('data ', data);
    if (data?.playNext || data?.playPrev) {
      setIsLoading(true);
      // currentPlayerRef?.current?.off('timeupdate');
      if (CHECK_STATUS) {
        // currentPlayerRef?.current?.off('pause');
        // currentPlayerRef?.current?.off('error');
      }
    }

    if (playThrottle.current) {
      clearTimeout(playThrottle.current);
    }

    playThrottle.current = setTimeout(async () => {
      // handleChangePlayData(data);
      try {
        if (data.hasOwnProperty('playList')) {
          realPlayList = data.playList;
          if (realPlayList.length === 1) {
            realPlayList = [
              ...realPlayList,
              ...realPlayList,
              ...realPlayList,
              ...realPlayList,
            ];
          }

          if (!realPlayList || realPlayList.length === 0) {
            alert('등록된 플레이리스트가 없습니다!');
            window?.opener?.postMessage(
              { windowStatus: 'close' },
              process.env.REACT_APP_ORIGIN,
            );
            window?.close();
            return;
          }
        }

        if (data.hasOwnProperty('playId')) {
          if (!data.playList || data?.playList?.length === 0) {
            alert('등록된 플레이리스트가 없습니다!');
            return;
          }

          let playIndex;
          data.playList.some((el, index) => {
            if (el.id === data.playId) {
              playIndex = index;
            }
            return el.id === data.playId;
          });

          if (playIndex === 0) {
            realPlayList = data.playList;
          } else if (playIndex === data.playList.length - 1) {
            const last = data.playList[playIndex];
            const restArr = data.playList.filter((el) => el.id !== last.id);
            realPlayList = [last, ...restArr];
          } else {
            const pastArray = data.playList.slice(0, playIndex);
            const newArray = data.playList.slice(
              playIndex,
              data.playList.length,
            );
            realPlayList = [...newArray, ...pastArray];
          }

          if (realPlayList.length === 1) {
            isOnlyOne = true;
            realPlayList = [
              ...realPlayList,
              ...realPlayList,
              ...realPlayList,
              ...realPlayList,
            ];
          }

          if (data?.playNext) {
            leftDuration = realPlayDuration;
            await handlePlayCurrentVideo(
              realPlayList[0].id,
              0,
              data?.playStart,
            );
            isPreparing = false;
          }

          if (data?.playPrev) {
            leftDuration = realPlayDuration;
            await handlePlayCurrentVideo(
              realPlayList[0].id,
              0,
              data?.playStart,
            );
            isPreparing = false;
          }

          if (data?.removed) {
            leftDuration = realPlayDuration;
            await handlePlayCurrentVideo(
              realPlayList[0].id,
              0,
              data?.playStart,
            );
          }
        }

        if (data.hasOwnProperty('currentPlayTime')) {
          currentPlayer?.setCurrentTime(data.currentPlayTime);
        }

        if (data.hasOwnProperty('playVolume')) {
          currentPlayer?.setVolume(data.playVolume / 100);
        }

        if (data.hasOwnProperty('playMute')) {
          if (data.playMute) {
            currentPlayer?.setVolume(0);
          } else {
            currentPlayer?.setVolume(data.playVolume / 100);
          }
        }

        if (data.hasOwnProperty('playLoop')) {
          isLoop = data.playLoop;
        }

        if (data.hasOwnProperty('playStart')) {
          try {
            if (currentPlayer) {
              if (data.playStart) {
                isUserStop = false;
                currentPlayer?.setVolume(0);
                currentPlayer?.play();
              } else {
                isUserStop = true;
                currentPlayer?.pause();
              }
            }
          } catch (err) {
            console.error(err);
          }
        }

        if (data.hasOwnProperty('playDuration')) {
          realPlayDuration =
            data.playDuration === 'auto' ? 0 : Number(data.playDuration);
          leftDuration =
            data.playDuration === 'auto' ? 0 : Number(data.playDuration);
          isVideoLengthPlay = data.playDuration === 'auto';
        }

        if (data.hasOwnProperty('isInit')) {
          if (data?.isInit) {
            await handleVideo(
              realPlayList[currentVideoIndex].id,
              currentVideoIndex,
              data.playStart,
              false,
            );
          }
        }
      } catch (err) {
        console.error(err);
        window?.opener?.postMessage(
          { windowStatus: 'close' },
          process.env.REACT_APP_ORIGIN,
        );
        window?.close();
      }
      if (data?.playNext || data?.playPrev) {
        setIsLoading(false);
      }
    }, 2000);
  };

  const receiveMessage = async (e) => {
    // 도메인 체크 X
    // if (e.origin !== process.env.REACT_APP_ORIGIN) return;
    if (e.data.mode === 'play') {
      await handleParentData(e.data);
    }
  };

  useEffect(() => {
    if (!isInit) return;
    window.addEventListener('message', receiveMessage, false);
    window?.opener?.postMessage(
      { windowStatus: 'ready' },
      process.env.REACT_APP_ORIGIN,
    );
    return () => {
      window?.removeEventListener('message', receiveMessage, false);
    };
  }, [isInit]);

  window.onbeforeunload = function (e) {
    window?.opener?.postMessage(
      { windowStatus: 'refresh' },
      process.env.REACT_APP_ORIGIN,
    );
  };

  const alertUser = () => {
    // window?.opener?.postMessage(
    //   { windowStatus: 'close' },
    //   process.env.REACT_APP_ORIGIN,
    // );
    // window.close();
  };

  const handleOnlineVideo = async () => {
    try {
    } catch (err) {
      console.log('handleOnlineVideo err ', err);
      console.log('handleOnlineVideo err response ', err?.response);
    }
  };

  const handleOfflineVideo = (e) => {
    console.log('offline!!!');

    alert(
      '네트워크가 오프라인상태가 되었습니다. 네트워크를 확인 후 다시 실행해주세요!',
    );

    const scope = new Sentry.Scope();
    scope.setTag('video', 'handleOfflineVideo');

    scope.setContext('offlineContext', {
      e: e,
      connection: {
        connection: window?.navigator?.connection,
        timestamp: moment(new Date()).format('YYYY-MM-DD hh:mm:ss'),
      },
      name,
      phone,
      estimate,
      contract,
      email,
      businessName,
      businessAddress,
    });

    Sentry.captureException(e, () => scope);
  };

  async function handleChangeNetwork(e) {
    // Handle change of connection type here.
    console.error(
      'CHANGE - Navigator.connection ',
      window?.navigator?.connection,
    );
    console.error('CHANGE - Navigator.connection eee ', e);
    console.error('CHANGE - Navigator.connection eee 111 ', e?.currentTarget);
    console.error('CHANGE - Navigator.connection eee 222 ', e?.srcElement);
    console.error('CHANGE - Navigator.connection eee 333 ', e?.target);

    if (window?.navigator?.onLine && !currentPlayer.playing) {
      currentPlayer?.setVolume(0);
      currentPlayer?.play()?.catch(handleVideoIssue);
    }

    const scope = new Sentry.Scope();
    scope.setTag('video', 'handleChangeNetwork');

    scope.setContext('changeNetworkContext', {
      e: e,
      errorNetwork: {
        currentTarget: e?.currentTarget,
        srcElement: e?.srcElement,
        target: e?.target,
        timestamp: moment(new Date()).format('YYYY-MM-DD hh:mm:ss'),
      },
      name,
      phone,
      estimate,
      contract,
      email,
      businessName,
      businessAddress,
    });

    Sentry.captureException(e, () => scope);
  }

  useEffect(() => {
    // window?.navigator?.connection?.addEventListener(
    //   'change',
    //   handleChangeNetwork,
    // );
    window.addEventListener('online', handleVideoIssue);
    window.addEventListener('offline', handleOfflineVideo);

    window.addEventListener('beforeunload', alertUser);
    window.addEventListener('webkitfullscreenchange', exitHandler, false);
    window.addEventListener('mozfullscreenchange', exitHandler, false);
    window.addEventListener('fullscreenchange', exitHandler, false);
    window.addEventListener('MSFullscreenChange', exitHandler, false);
    window.addEventListener('keydown', handleUserKeyPress, false);
    window.addEventListener('keypress', handleUserKeyPress, false);

    return () => {
      // window?.navigator?.connection?.removeEventListener(
      //   'change',
      //   handleChangeNetwork,
      // );
      window.removeEventListener('online', handleVideoIssue);
      window.removeEventListener('offline', handleOfflineVideo);

      window.removeEventListener('beforeunload', alertUser);
      window.removeEventListener('webkitfullscreenchange', exitHandler, false);
      window.removeEventListener('mozfullscreenchange', exitHandler, false);
      window.removeEventListener('fullscreenchange', exitHandler, false);
      window.removeEventListener('MSFullscreenChange', exitHandler, false);
      window.removeEventListener('keydown', handleUserKeyPress, false);
      window.addEventListener('keypress', handleUserKeyPress, false);
    };
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const res = await Promise.all([
          api.getMyEstimate().catch(() => {}),
          api.getMyContract().catch(() => {}),
        ]);
        const estimate = res[0]?.data;
        const contract = res[1]?.data;
        if (estimate && estimate?.status === 'CONFIRM') {
          if (
            contract &&
            (contract?.status === 'FINISH' || contract?.status === 'SCHEDULED')
          ) {
            if (estimate?.solutionType === 'PREMIUM') {
              setIsInit(true);
            } else if (estimate?.solutionType === 'STANDARD') {
              setIsInit(true);
            } else if (estimate?.solutionType === 'BASIC') {
              setIsInit(true);
            } else {
              alert('플레이는 솔루션 계약 완료 후 이용가능하십니다 : )');
              navigator('/');
              window?.opener?.postMessage(
                { windowStatus: 'close' },
                process.env.REACT_APP_ORIGIN,
              );
              window.close();
            }
          } else {
            alert('플레이는 솔루션 계약 완료 후 이용가능하십니다 : )');
            navigator('/');
            window?.opener?.postMessage(
              { windowStatus: 'close' },
              process.env.REACT_APP_ORIGIN,
            );
            window.close();
          }
        } else {
          alert('플레이는 솔루션 계약 완료 후 이용가능하십니다 : )');
          navigator('/');
          window?.opener?.postMessage(
            { windowStatus: 'close' },
            process.env.REACT_APP_ORIGIN,
          );
          window.close();
        }
      } catch (err) {
        alert('인증 실패');
        console.log(err);
        const scope = new Sentry.Scope();
        scope.setTag('estimateContract', 'estimateContractError');
        if (err?.config) {
          const { method, url, params, data, headers } = err.config; // axios의 error객체
          scope.setContext('estimateContract API Request Detail', {
            method,
            url,
            params,
            data,
            headers,
          });
        }
        if (err?.response) {
          const { data, status } = err.response;
          scope.setContext('estimateContract API Response Detail', {
            status,
            data,
          });
        }
        scope.setContext('estimateContractContext', {
          err: err?.response,
          timestamp: moment(new Date()).format('YYYY-MM-DD hh:mm:ss'),
          name,
          phone,
          estimate,
          contract,
          email,
          businessName,
          businessAddress,
        });
        Sentry.captureException(err, () => scope);
        alert(
          '권한이 확인되지 않습니다. 로그인 후 다시 한 번 확인해주세요 : )',
        );
        navigator('/');
        window?.opener?.postMessage(
          { windowStatus: 'close' },
          process.env.REACT_APP_ORIGIN,
        );
        window.close();
      }
    })();
  }, []);

  // 시간
  useEffect(() => {
    if (process.env.REACT_APP_TEMP_BLOCK === 'YES') return;
    if (!needRefetch) return;
    (async () => {
      try {
        const [time, advertises, info] = await Promise.all([
          api.getCurrentTime(),
          api.getAdvertisesToday(),
          api.getAdvertiseInfo(),
        ]);
        const date = moment(time?.data).format('YYMMDD');
        const logs = await api.getAdvertiseLogs(date);
        console.log('advertises ', advertises);
        console.log('logs ', logs);
        console.log('info ', info);
        setAdvertiseInterval(info?.data?.advertiseInterval);
        setToday(moment(time?.data).format('YYMMDD'));
        setAdvertises(advertises?.data);
        setLogs(logs?.data);
        setNeedRefetch(false);
      } catch (err) {
        console.log(err);
        console.log(err?.response);
        console.log('gogogogo? ', err?.response);
        // alert(
        //   '권한이 확인되지 않습니다. 로그인 후 다시 한 번 확인해주세요 : )',
        // );
        // navigator('/');
        // window?.opener?.postMessage(
        //   { windowStatus: 'close' },
        //   process.env.REACT_APP_ORIGIN,
        // );
        // window.close();
      }
    })();
  }, [needRefetch]);

  const adVideoEnded = async () => {
    console.log('adVideoEnded exec!!!!!! ');
    $(adPlayer.element)
      .removeClass('active')
      .fadeOut(1000, function () {
        $(this).remove(); // 현재 요소를 제거합니다.
        adPlayer.off('ended');
        adPlayer = null;
      });
  };

  // 광고 송출
  useEffect(() => {
    if (process.env.REACT_APP_TEMP_BLOCK === 'YES') return;
    if (timeRef.current) {
      clearInterval(timeRef.current);
    }

    timeRef.current = setInterval(() => {
      (async () => {
        try {
          let isLessThan30M = false;
          const res = await api.getCurrentTime();
          const hour = moment(res?.data).format('HH');
          const minute = Number(moment(res?.data).format('mm')) || 0;
          if (today !== moment(res?.data).format('YYMMDD')) {
            setNeedRefetch(true);
            return;
          }
          console.log('timeRef res ', res);
          let type = ADVERTISE_TYPE.A;
          if (advertiseInterval === 10) {
            if (minute < 10) {
              type = ADVERTISE_TYPE.A;
            } else if (minute < 20) {
              type = ADVERTISE_TYPE.B;
            } else if (minute < 30) {
              type = ADVERTISE_TYPE.C;
            } else if (minute < 40) {
              type = ADVERTISE_TYPE.D;
            } else if (minute < 50) {
              type = ADVERTISE_TYPE.E;
            } else {
              type = ADVERTISE_TYPE.F;
            }
          } else {
            if (minute < 5) {
              type = ADVERTISE_TYPE.A;
              isLessThan30M = true;
            } else if (minute < 10) {
              type = ADVERTISE_TYPE.B;
              isLessThan30M = true;
            } else if (minute < 15) {
              type = ADVERTISE_TYPE.C;
              isLessThan30M = true;
            } else if (minute < 20) {
              type = ADVERTISE_TYPE.D;
              isLessThan30M = true;
            } else if (minute < 25) {
              type = ADVERTISE_TYPE.E;
              isLessThan30M = true;
            } else if (minute < 30) {
              type = ADVERTISE_TYPE.F;
              isLessThan30M = true;
            } else if (minute < 35) {
              type = ADVERTISE_TYPE.A;
            } else if (minute < 40) {
              type = ADVERTISE_TYPE.B;
            } else if (minute < 45) {
              type = ADVERTISE_TYPE.C;
            } else if (minute < 50) {
              type = ADVERTISE_TYPE.D;
            } else if (minute < 55) {
              type = ADVERTISE_TYPE.E;
            } else {
              type = ADVERTISE_TYPE.F;
            }
          }

          console.log('timeRef advertises gogo ', advertises);
          console.log('timeRef logs gogo ', logs);
          console.log('timeRef hour gogo ', hour);
          console.log('timeRef type gogo ', type);
          console.log('timeRef advertiseInterval gogo ', advertiseInterval);
          console.log('timeRef isLessThan30M gogo ', isLessThan30M);

          if (
            !logs ||
            !logs[hour] ||
            !logs[hour][type] ||
            logs[hour][type]?.length === 0
          ) {
            return;
          }

          const playedCount = logs[hour][type]?.filter(
            (isPlayed) => isPlayed === true,
          )?.length;

          console.log('playedCount, gogogogo!!!! ', playedCount);

          if (advertiseInterval === 10) {
            if (playedCount >= 1 || !advertises[type]) return;
          } else {
            if (playedCount >= 2 || !advertises[type]) return;
            if (isLessThan30M && playedCount >= 1) return;
          }

          const player = await makeAdVideo(advertises[type]);
          adPlayer = player;

          console.log('adPlayer, gogogogo!!!! ', adPlayer);

          adPlayer.on('loaded', function () {
            console.log('adPlayer ', adPlayer);
            setTimeout(() => {
              $(adPlayer.element).addClass('active');
              console.log('adVideoEnded ', adVideoEnded);
              adPlayer.on('ended', adVideoEnded);
              adPlayer?.setVolume(0);
              adPlayer.play();
            }, 1000);
          });

          setCurrentAdvertise(type);

          if (advertiseInterval === 10) {
            await api.postAdvertiseLogs(
              type,
              moment(res?.data).format('YYMMDDHH'),
            );
            const temp = _.cloneDeep(logs);
            temp[hour][type].unshift(true);
            temp[hour][type].pop();
            setLogs(temp);
            console.log('timeRef, postAdvertiseLogs111 endend!!!! ', temp);
          } else {
            if (!isLessThan30M && playedCount === 0) {
              await api.postAdvertiseLogs(
                type,
                moment(res?.data).format('YYMMDDHH'),
              );
              await api.postAdvertiseLogs(
                type,
                moment(res?.data).format('YYMMDDHH'),
              );
              const temp = _.cloneDeep(logs);
              temp[hour][type].unshift(true);
              temp[hour][type].pop();
              temp[hour][type].unshift(true);
              temp[hour][type].pop();
              setLogs(temp);
              console.log('timeRef, postAdvertiseLogs222 endend!!!! ', temp);
            } else {
              await api.postAdvertiseLogs(
                type,
                moment(res?.data).format('YYMMDDHH'),
              );
              const temp = _.cloneDeep(logs);
              temp[hour][type].unshift(true);
              temp[hour][type].pop();
              setLogs(temp);
              console.log('timeRef, postAdvertiseLogs333 endend!!!! ', temp);
            }
          }
        } catch (err) {
          console.log(err);
          adVideoEnded();
        }
      })();
    }, 1000 * 60);

    return () => clearInterval(timeRef.current);
  }, [today, advertiseInterval, logs, advertises]);

  const handleClick = async (e, index) => {
    e.preventDefault();
    setIsFullScreen(true);
    window.document.documentElement.requestFullscreen();
  };

  if (!isInit) return <></>;

  return (
    <>
      {isLoading && <BeamMonsterLoader />}
      <AdvertisementPlay />
      <PlayWrapper
        isFullScreen={isFullScreen}
        playList={playInfo?.playList}
        onClick={handleClick}
      />
    </>
  );
}
